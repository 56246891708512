$color-active: $anchor-color-hover;
$width-ul: 230px;
$margin-bottom-footer-catalog: 10px;

.ya-menu {

    &--header-top {
        //overflow: hidden;
    }
}

.ya-menu__ul {

    .ya-menu--header-top & {
        @include cols(default, 24px, 'li');
    }

    .ya-menu--header-bottom & {
        display: flex;
        justify-content: space-between;
    }

    .ya-menu--footer-catalog & {
        width: 287px;
        @include cols(2, 20px, 'li');
        flex-wrap: wrap;
        margin-bottom: 0 - $margin-bottom-footer-catalog;
    }
}

.ya-menu__li {

    .ya-menu--header-bottom & {
        position: relative;
        z-index: $z-index-base;
    }

    .ya-menu--header-bottom.ya-right & {
        border-right: 1px solid #5b5b5b;
        flex-grow: 1;
    }

    .ya-menu--footer-catalog & {
        margin-bottom: $margin-bottom-footer-catalog;
    }

    .ya-menu--footer-buyer & {

        &:not(:last-child) {
            margin-bottom: 9px;
        }
    }
}


.ya-menu__a {
    text-decoration: none;
    transition: all $global-transition-duration linear;

    .ya-menu--header-top & {
        font-size: 14px;
        font-family: $font-helvetica;
        color: #474747;

        &:hover {
            color: $anchor-color-hover;
        }
    }

    .ya-menu--header-top .ya-active & {
        color: $anchor-color-hover;
    }

    .ya-menu--header-bottom & {
        display: flex;
        align-items: center;
        min-height: 40px;
        width: 100%;
        justify-content: center;
        color: white;
        font-size: 15px;
        font-weight: 700;

        &:hover {
            background: #8b2825;
        }
    }

    .ya-menu__sub-ul + & {
        background: $anchor-color;
        cursor: pointer;
        width: $width-ul;
        justify-content: space-between;
        padding: 0 18px;

        &:hover,
        .ya-visible > & {
            background: #eeeeee;
            color: $anchor-color-hover;
        }
    }

    .ya-menu__li:hover > .ya-menu__sub-ul + & {
        background: #eeeeee;
        color: $anchor-color-hover;
    }

    .ya-menu__sub-ul & {
        width: 100%;
        padding: 10px 20px;
        justify-content: space-between;
        color: #2e2e30;
        position: relative;
        font-weight: 400;
        background: none;
        border-bottom: 1px solid #eeeeee;
        transition: all $global-transition-duration linear;

        .ya-menu__sub-li:last-child & {
            border-bottom: none;
        }
    }

    .ya-menu__sub-ul + & {
        font-weight: 700;
    }

    .ya-menu__sub-li:hover > & {
        color: $anchor-color-hover;
        background: none;
    }

    .ya-footer & {
        color: #eaeaea;
        font-family: $font-helvetica;

        &:hover {
            color: $color-active;
        }
    }

    .ya-menu--footer-catalog & {
        font-size: 13px;
    }

    .ya-menu--sidebar & {
        color: #3c3d41;
        font-family: $font-helvetica;
        display: block;
        padding: $sidebar-container-padding-y $sidebar-container-padding-x;
        border-top: 1px solid $sidebar-border-color;

        &:hover {
            color: $anchor-color-hover;
        }
    }

    .ya-menu--sidebar .ya-menu__li:first-child & {
        border-top: none;
    }
}

.ya-icon {

    .ya-menu__a &,
    .ya-menu__sub-a & {
        margin: 0 0 0 20px;
        flex-shrink: 0;
    }
}

.ya-menu__sub-ul {
    position: absolute;
    top: 100%;
    left: 0;
    width: $width-ul;
    background-color: #f9f9f9;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0.5px 0.9px 2px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transition: all $global-transition-duration linear;

    & & {
        left: 100%;
        top: 0;
        border-radius: 5px;
        border: 1px solid #eee;
        border-top: none;
    }

    .ya-menu__sub-li:hover > &,
    .ya-visible > & {
        opacity: 1;
        visibility: visible;
    }
}

.ya-menu__sub-li {
    display: flex;
}
