// yaResetCss, Sergey Yarkevich

audio:not([controls]) {
    display: none; // Chrome 44-, iOS 8+, Safari 9+
    height: 0;
}

button {
    overflow: visible; // Internet Explorer 11-
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    outline: none;
    color: inherit;
    font: inherit;
    line-height: inherit;
    background: transparent;
    padding: 0;
    border: 0;
    text-transform: none;

    &[disabled] {
        cursor: default;
    }
}

details {
    display: block; // Edge 12+, Firefox 40+, Internet Explorer 11-, Windows Phone 8.1+
}

html {
    // -ms-overflow-style: -ms-autohiding-scrollbar; // Edge 12+, Internet Explorer 11-
    overflow-y: scroll; // All browsers without overlaying scrollbars
    -webkit-text-size-adjust: 100%; // iOS 8+
    -ms-text-size-adjust: 100%;
}

input {
    -webkit-border-radius: 0; // iOS 8+
    outline: none;
    color: inherit;
    font: inherit;
    line-height: normal;
    word-break: normal;

    &::-ms-clear {
        display: none;
    }

    &[type="button"], &[type="reset"], &[type="submit"] {
        -webkit-appearance: none;
        cursor: pointer;
    }

    &[type="number"] {
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            height: auto;
            -webkit-appearance: none;
        }
    }

    &[type="search"] {
        -webkit-appearance: textfield; // Chrome 45+, Safari 9+

        &::-webkit-search-cancel-button,
        &::-webkit-search-decoration {
            -webkit-appearance: none; // Chrome 45+, Safari 9+
        }
    }

    html & {

        &[disabled] {
            cursor: default;
        }
    }
}

main {
    display: block; // Android 4.3-, Internet Explorer 11-, Windows Phone 8.1+
}

pre {
    overflow: auto; // Internet Explorer 11-
}

progress {
    display: inline-block; // Internet Explorer 11-, Windows Phone 8.1+
}

summary {
    display: block; // Firefox 40+, Internet Explorer 11-, Windows Phone 8.1+
}

svg:not(:root) {
    overflow: hidden; // Internet Explorer 11-
}

template {
    display: none; // Android 4.3-, Internet Explorer 11-, iOS 7-, Safari 7-, Windows Phone 8.1+
}

textarea {
    overflow: auto; // Edge 12+, Internet Explorer 11-
    resize: none;
    outline: none;
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    display: block;
}

[hidden] {
    display: none; // Internet Explorer 10-
}

*,
:before,
:after {
    box-sizing: inherit;
}

* {
    margin: 0;
    padding: 0;
}

ol, ul {
    list-style: none;
}

li {
    list-style: none;
}

select {
    -moz-appearance: none;    // Firefox 40+
    -webkit-appearance: none; // Chrome 45+
    color: inherit;
    font: inherit;
    width: 100%;
    text-transform: none;
    outline: none;
    cursor: pointer;

    &::-ms-expand {
        display: none;        // Edge 12+, Internet Explorer 11-
    }

    &::-ms-value {
        color: currentColor;  // Edge 12+, Internet Explorer 11-
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

img {
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
    display: block;
    border: 0;
}

a {
    background-color: transparent;
    outline: none !important;

    &:active,
    &:hover {
        outline: none;
    }
}

b,
strong {
    font-weight: bold;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

hr {
    box-sizing: content-box;
    height: 0;
}

pre {
    overflow: auto;
}

fieldset {
    border: none;
    padding: 0;
}

td,
th {
    padding: 0;
}

body {
    word-wrap: break-word; /* для ie */
    overflow-wrap: break-word;
    word-break: break-word;
}

input[placeholder]          {text-overflow:ellipsis;}
input::-moz-placeholder     {text-overflow:ellipsis;}
input:-moz-placeholder      {text-overflow:ellipsis;}
input:-ms-input-placeholder {text-overflow:ellipsis;}
