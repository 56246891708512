  $basket-2: 86px 62px -86px -62px 27px 24px 203px 180px 'sprite.png';
  $basket-red: 86px 31px -86px -31px 32px 27px 203px 180px 'sprite.png';
  $basket: 86px 0px -86px 0px 32px 27px 203px 180px 'sprite.png';
  $checkbox-checked: 0px 122px 0px -122px 20px 20px 203px 180px 'sprite.png';
  $checkbox: 122px 90px -122px -90px 20px 20px 203px 180px 'sprite.png';
  $clock: 0px 164px 0px -164px 16px 16px 203px 180px 'sprite.png';
  $close-2: 154px 110px -154px -110px 17px 17px 203px 180px 'sprite.png';
  $close-3-hover: 176px 105px -176px -105px 14px 13px 203px 180px 'sprite.png';
  $close-3: 176px 88px -176px -88px 14px 13px 203px 180px 'sprite.png';
  $close-white: 195px 32px -195px -32px 8px 8px 203px 180px 'sprite.png';
  $close: 195px 44px -195px -44px 8px 8px 203px 180px 'sprite.png';
  $dots-blue: 85px 164px -85px -164px 13px 3px 203px 180px 'sprite.png';
  $dots-red-2: 68px 164px -68px -164px 13px 3px 203px 180px 'sprite.png';
  $dots-red: 48px 164px -48px -164px 16px 4px 203px 180px 'sprite.png';
  $dots: 122px 114px -122px -114px 16px 4px 203px 180px 'sprite.png';
  $email: 0px 146px 0px -146px 20px 14px 203px 180px 'sprite.png';
  $grid: 76px 122px -76px -122px 18px 18px 203px 180px 'sprite.png';
  $info-2: 98px 122px -98px -122px 18px 18px 203px 180px 'sprite.png';
  $info-red: 120px 122px -120px -122px 18px 18px 203px 180px 'sprite.png';
  $info-white: 195px 70px -195px -70px 4px 10px 203px 180px 'sprite.png';
  $info: 195px 56px -195px -56px 4px 10px 203px 180px 'sprite.png';
  $map-2: 176px 22px -176px -22px 15px 18px 203px 180px 'sprite.png';
  $map: 176px 0px -176px 0px 15px 18px 203px 180px 'sprite.png';
  $navicon-white: 122px 44px -122px -44px 23px 18px 203px 180px 'sprite.png';
  $navicon: 122px 22px -122px -22px 23px 18px 203px 180px 'sprite.png';
  $paper: 0px 0px 0px 0px 82px 90px 203px 180px 'sprite.png';
  $phone: 154px 44px -154px -44px 18px 18px 203px 180px 'sprite.png';
  $pointer-circle-white: 74px 94px -74px -94px 28px 18px 203px 180px 'sprite.png';
  $pointer-circle: 122px 0px -122px 0px 28px 18px 203px 180px 'sprite.png';
  $pointer-down: 32px 164px -32px -164px 12px 8px 203px 180px 'sprite.png';
  $pointer-left-4-red: 68px 146px -68px -146px 18px 14px 203px 180px 'sprite.png';
  $pointer-left-4-white: 90px 146px -90px -146px 18px 14px 203px 180px 'sprite.png';
  $pointer-left-4: 24px 146px -24px -146px 18px 14px 203px 180px 'sprite.png';
  $pointer-left-big-2: 176px 44px -176px -44px 11px 18px 203px 180px 'sprite.png';
  $pointer-left-big: 37px 94px -37px -94px 33px 24px 203px 180px 'sprite.png';
  $pointer-left-dark: 142px 122px -142px -122px 8px 14px 203px 180px 'sprite.png';
  $pointer-left: 176px 140px -176px -140px 8px 14px 203px 180px 'sprite.png';
  $pointer-right-2: 195px 84px -195px -84px 5px 8px 203px 180px 'sprite.png';
  $pointer-right-3-dark: 176px 122px -176px -122px 8px 14px 203px 180px 'sprite.png';
  $pointer-right-3: 106px 94px -106px -94px 8px 14px 203px 180px 'sprite.png';
  $pointer-right-4-red: 112px 146px -112px -146px 18px 14px 203px 180px 'sprite.png';
  $pointer-right-4-white: 46px 146px -46px -146px 18px 14px 203px 180px 'sprite.png';
  $pointer-right-4: 134px 146px -134px -146px 18px 14px 203px 180px 'sprite.png';
  $pointer-right-big-2: 176px 66px -176px -66px 11px 18px 203px 180px 'sprite.png';
  $pointer-right-big: 0px 94px 0px -94px 33px 24px 203px 180px 'sprite.png';
  $pointer-right-red-2: 195px 16px -195px -16px 6px 12px 203px 180px 'sprite.png';
  $pointer-right-red: 195px 0px -195px 0px 8px 12px 203px 180px 'sprite.png';
  $pointer-right: 20px 164px -20px -164px 8px 12px 203px 180px 'sprite.png';
  $pointer-top-white: 156px 146px -156px -146px 16px 10px 203px 180px 'sprite.png';
  $pointer-top: 154px 131px -154px -131px 16px 10px 203px 180px 'sprite.png';
  $search: 122px 66px -122px -66px 20px 20px 203px 180px 'sprite.png';
  $sort-1-black: 154px 0px -154px 0px 18px 18px 203px 180px 'sprite.png';
  $sort-1: 154px 22px -154px -22px 18px 18px 203px 180px 'sprite.png';
  $sort-2-black: 154px 88px -154px -88px 18px 18px 203px 180px 'sprite.png';
  $sort-2: 154px 66px -154px -66px 18px 18px 203px 180px 'sprite.png';
  $sort-3-black: 50px 122px -50px -122px 22px 18px 203px 180px 'sprite.png';
  $sort-3: 24px 122px -24px -122px 22px 18px 203px 180px 'sprite.png';

  @mixin sprite-position($sprite) {
    $sprite-offset-x: nth($sprite, 3);
    $sprite-offset-y: nth($sprite, 4);
    background-position: $sprite-offset-x  $sprite-offset-y;
  }

  @mixin sprite-image($sprite) {
    $sprite-image: nth($sprite, 9);
    background-image: url(%=static=%png-sprite/96dpi/#{$sprite-image});
  }

  @mixin sprite-mixin-template($sprite) {
    @include sprite-image($sprite);
    @include sprite-position($sprite);
    width: nth($sprite, 5);
    height: nth($sprite, 6);
  }

  @mixin bg($sprite, $repeat: no-repeat) {
    @include sprite-mixin-template($sprite);
    $sprite-total-width: nth($sprite, 7);
    $sprite-total-height: nth($sprite, 8);
    background-size: $sprite-total-width, $sprite-total-height;
    background-repeat: $repeat;

      @media screen and (-webkit-min-device-pixel-ratio: 2), only screen and ( min-resolution: 192dpi), only screen and ( min-resolution: 2dppx), only screen and (min-device-pixel-ratio: 2) {
          $sprite-image: nth($sprite, 9);
          background-image: url(%=static=%png-sprite/192dpi/#{$sprite-image});
      }


  }
