#ya-contacts-map {
    width: 100%;
    height: 500px;
}

.ya-contacts__map-info {
    position: absolute;
    z-index: $z-index-base;
    left: 0;
    top: 27px;
    width: 296px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 1.7px 2.5px 6px rgba(0, 0, 0, 0.2);
    padding: 8px;
}

.ya-contacts__map-info-container {
    position: relative;
    z-index: $z-index-base;
}

.ya-contacts__map-info-in {
    position: relative;
    z-index: $z-index-base;
    padding: 20px 29px 33px;
}

.ya-contacts__list-ul {
    @include cols(default, 30px in, 'li');
    //flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
}

.ya-contacts__list-icon {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
    margin-bottom: 17px;

    @include vertical-align('div');
}

.ya-contacts__list-title {
    display: block;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
}

.ya-contacts__list-subtitle {
    display: block;
    color: #616161;
    margin-bottom: 13px;
}

.ya-contacts__list {
    padding: 0 70px;
    margin-bottom: 35px;
}

.ya-contacts__list-text {
    font-size: 16px;
    color: $anchor-color;

    &[href],
    [data-remodal-target] & {
        text-decoration: underline;
    }

    &:hover {
        color: $anchor-color-hover;
        text-decoration: none;
    }
}

.ya-contacts__map-info-title {
    display: block;
    color: #1b1b1b;
    font-size: 23px;
    font-weight: 700;
    margin-bottom: 23px;
}

.ya-contacts__map-info-row {
    @include cols(shrink default, 9px in);
    align-items: center;
}

.ya-contacts__map-info-text {
    font-size: 16px;
    line-height: 1.15;
}
