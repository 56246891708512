.ya-banners {
    margin-bottom: 32px;

    &.ya-move-top {
        background-color: #f2f3f5;
        border-top: 1px solid #dfdfdf;
        border-bottom: 1px solid #dfdfdf;
        padding: 15px 0;
        margin-bottom: 0;
    }
}

.ya-banners__ul {
    @include cols(default, 0, 'li');
    justify-content: space-between;

    .ya-move-top & {
        position: relative;
        top: -35px;
    }
}
