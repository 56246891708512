.ya-card__row {

    &.ya-top {
        @include cols(shrink auto shrink, 20px in);
        margin-bottom: 42px;
    }

    &.ya-bottom {
        @include cols(2, 26px in);
        margin-bottom: 14px;
    }
}

.ya-card__info {
    @include cols(2);
    align-items: center;
    font-family: $font-helvetica;
    padding: 3px 0;
}

.ya-card__info-text {
    padding: 8px 0 6px;
    font-size: 13px;

    & + & {
        border-left: 1px solid $sidebar-border-color;
        padding-left: 14px;
    }
}

p {

    .ya-availability & {

        &:before {
            content: '';
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 6px;
        }
    }

    .ya-availability.ya-in & {

        &:before {
            background-color: #82d889;
        }
    }

    .ya-availability.ya-not & {

        &:before {
            background-color: $anchor-color;
        }
    }
}

img {

    [class*=ya-slider-container--card] .ya-card__img & {
        max-height: 100%;
    }

    .ya-card__list-info-icon & {
        display: inline-block;
    }
}

.ya-card__img {
    height: 100%;
    padding: 4px;

    .ya-slider-container--card & {
        padding: 20px;
    }

    @include vertical-align('img');
}

.ya-card__table {
    table-layout: fixed;
    width: 100%;
    font-family: $font-helvetica;
    margin-bottom: 20px;
}

tr {

    .ya-card__table & {
        border-top: 1px solid #dfdfdf;

        &:last-child {
            border-bottom: 1px solid #dfdfdf;
        }
    }
}

td {

    .ya-card__table & {
        border-left: 1px solid #dfdfdf;
        width: 50%;
        height: 41px;
        padding: 5px 9px;
        vertical-align: middle;

        &:first-child {
            border-left: none;
        }
    }

    .ya-card__table tr:nth-child(2n+1) & {
        background-color: #f2f2f2;
    }
}

.ya-card__sidebar-container {
    padding: 20px 0;

    & + & {
        border-top: 1px solid $sidebar-border-color;
    }
}

.ya-card__list-info__li {
    @include cols(shrink default, 6px in);
    align-items: center;
    margin-bottom: 13px;

    &:last-child {
        margin-bottom: 0;
    }
}

.ya-card__list-info-icon {
    text-align: center;
    width: 36px;
    font-size: 0;
}

.ya-card__list-info-text {
    color: #99999b;
    font-size: 13px;
    font-weight: 700;
    font-family: $font-helvetica;
}

.ya-card__title {
    display: block;
    color: #1b1b1b;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 14px;

    &.ya-margin-md {
        margin-bottom: 11px;
    }
}

.ya-card__docs-ul {
    @include cols(3, 15px, 'li');
    flex-wrap: wrap;
}

.ya-card__docs-li {
    margin-bottom: 20px;
}

.ya-card__docs-row {
    @include cols(shrink default, 10px in);
}

.ya-card__docs-icon {
    position: relative;
    width: 50px;
    height: 60px;
    background-color: #f2f2f2;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
}

.ya-card__docs-icon-type {
    position: absolute;
    top: 10px;
    left: -1px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    background-color: white;
    border: 1px solid #dfdfdf;
    padding: 3px 5px 1px;

    &.ya-red {
        color: #e4281a;
    }

    &.ya-purple {
        color: #4000e3;
    }

    &.ya-green {
        color: #06b022;
    }
}

.ya-card__docs-size {
    color: #c1c1c1;
    display: block;
    font-size: 12px;
    margin-top: 4px;
}

.ya-card__docs-a {
    text-decoration: none;
    color: #418bfa;

    &:hover {
        text-decoration: underline;
    }
}

.ya-card__set {
    margin-bottom: 25px;
}

.ya-card__set-a {
    @include cols(default 38%, 30px in, 'span');
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: inherit;
    transition: color $global-transition-duration linear;

    &:hover {
        color: $anchor-color-hover;
    }
}

.ya-card__set-name {
    font-family: $font-helvetica;
}

.ya-card__set-price {
    text-align: right;
    font-weight: 700;
}

.ya-card__set-title {
    display: block;
    font-family: $font-helvetica;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 18px;
}

.ya-card__set-li {
    margin-bottom: 13px;

    &:last-child {
        margin-bottom: 0;
    }
}
