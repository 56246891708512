.ya-pagination {
    border-top: 1px solid #f2f2f2;
    padding-top: 19px;
    margin-bottom: 37px;
}

.ya-pagination__row {
    @include cols(default, 20px in);
    justify-content: space-between;
    align-items: center;
}

.ya-button {

    .ya-pagination & {
        font-size: 16px;
        font-family: $font-helvetica;
        font-weight: 700;
    }
}
