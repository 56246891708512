$border-color: #d7d7d7;

%table-col {
    padding: 12px 23px;

    .ya-view-basket-table-1 & {

        &:first-child {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.ya-basket-table {
    //margin-bottom: 26px;
}

.ya-basket-table__view {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -28px;
    position: relative;
}

.ya-basket-table__table {
    table-layout: fixed;
    width: 100%;
    margin-bottom: 23px;
}

th {

    .ya-basket-table__table & {
        @extend %table-col;
        padding-top: 10px;
        padding-bottom: 10px;
        font-family: $font-helvetica;
        font-weight: 700;
        color: #b8bebf;

        &:first-child {
            text-align: left;
        }
    }
}

td {

    .ya-basket-table__table & {
        @extend %table-col;
        border-left: 1px solid $border-color;
        border-top: 1px solid $border-color;

        &:first-child {
            border-left: none;
        }
    }

    .ya-basket-table__table tr:last-child & {
        border-bottom: 1px solid $border-color;
    }

    .ya-view-basket-table-1 & {
        padding-top: 8px;
        padding-bottom: 6px;
    }
}

.ya-basket-table__table-row {

    &.ya-main {
        @include cols(shrink default, 23px);
    }

    &.ya-name {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }
}

.ya-col {

    .ya-basket-table__table-row & {
        display: flex;
    }
}

.ya-basket-table__table-name {
    text-decoration: none;
    color: inherit;
    font-size: 16px;
    transition: color $global-transition-duration linear;
    line-height: 1.1;

    &:hover {
        color: $anchor-color-hover;
    }
}

.ya-basket-table__table-a {
    display: block;
}

.ya-basket-table__table-img {
    width: 76px;
}

.ya-hidden-view-basket-table-1 {

    .ya-view-basket-table-1 & {
        display: none;
    }
}

.ya-basket-table__row {
    @include cols(auto shrink);
}

.ya-basket-table__delete {
    @include vertical-align('span');
}

