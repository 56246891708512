.jfilestyle {
    //display: inline-block;
    //margin: 0px 0px 10px 0px;
    //padding: 0px;
    position: relative;
    border-collapse: separate;
}

div.jfilestyle label, div.jfilestyle input {
    //font-family: $font-helvetica;
}

div.jfilestyle input {
    //border: 1px solid #c0c0c0;
    //background: #d9d9d9;
    //margin: 0px -5px 0px 0px;
    //vertical-align: middle;
    //padding: 7px 14px;
    //font-size: 14px;
    //border-radius: 4px;
    //color: #8d8d8d;
    //cursor: default;
    //line-height: normal;
    border: none;
    margin-left: 17px;
    color: #7a7a7a;
    font-size: 12px;
    background: none;
}

div.jfilestyle label {
    //display: inline-block;
    //border: 1px solid #c0c0c0;
    //background: #ffffff;
    //padding: 7px 14px;
    //color: #0662ba;
    //vertical-align: middle;
    //line-height: normal;
    //text-align: center;
    //margin: 0px;
    //font-size: 14px;
    //width: auto;
    //border-radius: 4px;
    //font-weight: normal;
    @extend .ya-button;
    @extend .ya-button--gray-transparent-dark;
}

div.jfilestyle.jfilestyle-corner input:first-child {
    //border-right: 0;
}

div.jfilestyle.jfilestyle-corner input:last-child {
    //border-left: 0;
}

div.jfilestyle.jfilestyle-corner input:first-child,
div.jfilestyle.jfilestyle-corner span:first-child > label {
    //border-radius: 4px 0 0 4px;
}

div.jfilestyle.jfilestyle-corner input:last-child,
div.jfilestyle.jfilestyle-corner span:last-child > label {
    //border-radius: 0 4px 4px 0;
}

div.jfilestyle label[disabled] {
    pointer-events: none;
    opacity: 0.6;
    filter: alpha(opacity=65);
    cursor: not-allowed;
}

div.jfilestyle label:hover {
    cursor: pointer;
    //opacity: 0.9;
}

div.jfilestyle .count-jfilestyle {
    background: #303030;
    color: #fff;
    border-radius: 50%;
    padding: 1px 5px;
    font-size: 12px;
    vertical-align: middle;
}




