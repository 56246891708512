.ya-order {
    margin-bottom: 30px;
}

.ya-order__num {
    width: 44px;
    height: 44px;
    background-color: #eee;
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    line-height: 44px;
    color: #717275;
    font-size: 23px;
    display: block;
    text-align: center;
}

.ya-order__header-row {
    @include cols(shrink default, 10px in);
}

.ya-order__title {
    display: block;
    color: #1b1b1b;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 3px;
}

.ya-order__desc {
    color: #717275;
    display: block;

    &:not(:last-child) {
        margin-bottom: 7px;
    }
}

.ya-order__container {
    border-left: 1px solid #d7d7d7;
    padding: 20px 0 72px 62px;
    margin: 10px 0 10px 22px;
}

$col-margin-bottom-account: 18px;

.ya-order__row {

    &.ya-main {
        @include cols(auto shrink);
    }

    &.ya-account {
        @include cols(2, 28px);
        flex-wrap: wrap;
        margin-bottom: 0 - $col-margin-bottom-account;
    }
}

.ya-col {

    .ya-order__row.ya-account & {
        margin-bottom: $col-margin-bottom-account;
    }
}

$tiles-margin-bottom: 20px;

.ya-order__tiles {

    &:not(:last-child) {
        margin-bottom: 20px;
    }
}

.ya-order__tiles-ul {
    @include cols(default, 50px, 'li');
    flex-wrap: wrap;
    margin-bottom: 0 - $tiles-margin-bottom;
}

.ya-order__tiles-li {
    margin-bottom: $tiles-margin-bottom;
}

.ya-order__tile {
    display: block;
}

.ya-order__tile-img {
    position: relative;
    width: 41px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;

    input[hidden] ~ .ya-order__tile-row & {
        position: static;
    }
}

.ya-order__tile-row {
    @include cols(shrink default, 12px in);
    align-items: center;
    transition: background $global-transition-duration linear;

    input[hidden] ~ & {
        position: relative;
        z-index: 0;
        cursor: pointer;
        padding: 9px 23px 5px 10px;
        min-width: 210px;
        @include cols(shrink default, 7px in);

        &:hover {
            background: #ffdc17;
        }
    }

    input:checked ~ & {
        background: #ffdc17;
        cursor: default;
    }
}

.ya-order__tile-text {
    color: #1b1b1b;
    font-family: $font-helvetica;
    font-size: 18px;
}

.ya-order__text {
    font-family: $font-helvetica;
}

