.ya-success__container {
    width: 500px;
    margin: 58px auto 170px;
    text-align: center;
}

.ya-success__title {
    display: block;
    color: #3c3d41;
    font-size: 22px;
    line-height: 1;
    font-weight: 700;
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 25px;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 341px;
        height: 1px;
        background: #dfdfdf;
    }
}

.ya-success__icon {
    display: inline-block;
    margin-bottom: 30px;
}
