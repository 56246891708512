.ya-general-catalog {
    margin-bottom: 42px;
    overflow: hidden;
}

.ya-general-catalog__ul {

    &.ya-main {
        @include cols(4, 20px, 'li');
        flex-wrap: wrap;
    }
}

.ya-general-catalog__li {
    margin-bottom: 2px;
    position: relative;
    padding-left: 10px;

    .ya-general-catalog__ul.ya-main > & {
        margin-bottom: 66px;
    }
}

.ya-general-catalog__title {
    position: relative;
    padding-bottom: 6px;
    margin-bottom: 16px;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        width: 40px;
        background-color: #d1d1d1;
    }
}

.ya-general-catalog__title-a {
    color: #3c3d41;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    transition: color $global-transition-duration linear;

    &:hover {
        color: $anchor-color-hover;
    }
}

.ya-general-catalog__a {
    font-size: 16px;
    color: #76777a;
    text-decoration: none;
    transition: color $global-transition-duration linear;

    &:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #76777a;
        position: absolute;
        top: 7px;
        left: 0;
        transition: background $global-transition-duration linear;
    }

    &:hover {
        color: $anchor-color-hover;

        &:before {
            background-color: $anchor-color-hover;
        }
    }
}
