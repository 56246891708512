// placeholders //
// ---------------

@import "placeholders";


// Селекторы //
// ------------

.#{$class-container} {
    display: block;

    @if $global-width == 0 {
        width: 100%;
    } @else {
        width: $global-width + $global-padding * 2;
        margin: 0 auto;
    }

    padding: 0 $global-padding;

    .ya-content__main &,
    .remodal &,
    [class*=ya-sidebar] & {
        padding: 0;
        width: 100%;
    }
}

.#{$class-container-page} {
    min-width: $global-width + $global-padding * 2;
    overflow: hidden;
    //overflow-y: auto;

    &.ya-is-progress {

        &:hover {
            cursor: progress;
        }
    }
}

.#{$class-title} {
    display: block;
    font-family: $header-font-family;
    font-size: $header-font-size;
    font-weight: $header-font-weight;
    font-style: $header-font-style;
    color: $header-color;
    line-height: $header-lineheight;
    margin-bottom: $header-margin-bottom;
    max-height: 100%;
}

.#{$class-container-text} {
    max-height: 100%;

    p {
        line-height: $paragraph-lineheight;
        margin-bottom: $paragraph-margin-bottom;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        list-style: $list-style-position $list-style-type;
    }

    ul,
    ol {
        margin-bottom: $paragraph-margin-bottom;
        margin-left: $list-side-margin;

        &:last-child {
            margin-bottom: 0;
        }

        li {
            line-height: $list-lineheight;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    ul ul,
    ol ol,
    ul ol,
    ol ul {
        margin-left: $list-nested-side-margin;
    }

    img {
        margin: 20px;
    }

    &.ya-margin-collapse {

        p {
            margin-bottom: 0;
        }

        ul,
        ol {
            margin-bottom: 0;
        }
    }

    &.ya-helvetica {
        font-family: $font-helvetica;
        color: #313232;
    }

    &.ya-margin-md {
        margin-bottom: 17px;
    }

    &.ya-margin-sm {
        margin-bottom: 10px;
    }

    .ya-card & {
        font-family: $font-helvetica;
        margin-bottom: 21px;
    }
}

.#{$class-icon} {
    display: block;
    position: relative;
    transform: translateZ(0);
    background-repeat: no-repeat;
    margin: 0 auto;

    &:before, &:after {
        content: '';
        //transition: opacity 0.1s linear;
        background-repeat: no-repeat;
    }

    &:before {
        opacity: 1;
        display: block;
        //transition-duration: 0s;
    }

    &:after {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
    }

    .#{$button-class} & {
        display: inline-block;
        vertical-align: middle;
    }

    &--info {
        //top: -1px;
        //right: -1px;
    }
}

.#{$class-link} {
    color: $anchor-color;
    transition: all $anchor-transition-duration linear;
    border-bottom: $anchor-border;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $anchor-color-hover;
        border-color: $anchor-border-color-hover;
        text-decoration: none;
    }
}

.#{$class-hidden} {
    display: none;
}

.ya-rouble {
    // font-family: $font-rouble;
}

///////////////////////////////////////////////////

html {
    font-size: 10px;
}

body {
    box-sizing: $global-box-sizing;
    color: $global-font-color;
    font-family: $font-main;
    font-size: $global-font-size;
    line-height: $global-lineheight;
    font-weight: $global-weight-normal;

    @if ($body-antialiased) {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

::-moz-selection {
    background-color: $selection-background-color;
    color: $selection-color;
    text-shadow: $selection-text-shadow;
}

::selection {
    background-color: $selection-background-color;
    color: $selection-color;
    text-shadow: $selection-text-shadow;
}

b,
strong {
    font-weight: $global-weight-bold;
}

.ya-page__title {
    display: block;
    color: #1b1b1b;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 30px;
}

.ya-page__sub-title {
    display: block;
    font-size: 20px;
    margin-bottom: 29px;

    &.ya-lg {
        font-size: 23px;
        margin-bottom: 20px;
    }
}

.ya-section__title {
    display: block;
    color: #1b1b1b;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 31px;
}

.ya-section {
    padding: 23px 0 58px;

    .ya-content__main & {
        padding: 0;
    }
}

.ya-no-margin {
    margin-bottom: 0 !important;
}

.ya-center {
    text-align: center;
}

.ya-angle {

    &:after,
    &:before {
        content: '';
        position: absolute;
        z-index: $z-index-hidden;
        border: 1px solid transparent;
    }

    &.ya-x {

        &:after,
        &:before {
            width: 100%;
            left: 0;
            height: 41px;
            border-left-color: #dfdfdf;
            border-right-color: #dfdfdf;
        }

        &:before {
            top: 0;

            .ya-slider-container--card-nav & {
                top: -1px;
            }
        }

        &:after {
            bottom: 0;

            .ya-slider-container--card-nav & {
                bottom: -1px;
            }
        }

        &.ya-md {

            &:after,
            &:before {
                height: 19px;

                .ya-slider-container--card-nav & {
                    width: auto;
                    left: -1px;
                    right: -1px;
                }
            }
        }

        &.ya-sm {

            &:after,
            &:before {
                height: 14px;
            }
        }
    }

    &.ya-y {

        &:after,
        &:before {
            height: 100%;
            top: 0;
            width: 41px;
            border-top-color: #dfdfdf;
            border-bottom-color: #dfdfdf;
        }

        &:before {
            left: 0;

            .ya-slider-container--card-nav & {
                left: -1px;
            }

        }

        &:after {
            right: 0;

            .ya-slider-container--card-nav & {
                right: -1px;
            }
        }

        &.ya-md {

            &:after,
            &:before {
                width: 19px;

                .ya-slider-container--card-nav & {
                    height: auto;
                    top: -1px;
                    bottom: -1px;
                }
            }
        }

        &.ya-sm {

            &:after,
            &:before {
                width: 14px;
            }
        }
    }
}

.ya-full-window {

    $correct-width: 1px; // для ликвидации полоски шириной 1px по краям браузера

    @if $global-width == 0 {
        & > div {
            margin-left: 0 - $global-padding;
            margin-right: 0 - $global-padding;
        }
    } @else {
        @include breakpoint($global-width + $global-padding * 2) {
            width: 50vw;
            margin-left: 50%;

            & > div {
                width: 100vw; /* включает в себя полосу прокрутки от body */
                margin-left: -100%;
            }
        }
        @include breakpoint($global-width + $global-padding * 2 - 1 down) {

            & > div {
                margin-left: 0 - $global-padding - $correct-width;
                margin-right: 0 - $global-padding - $correct-width;
            }
        }
    }

    .ya-container {
        padding: 0 $global-padding !important;

        @if $global-width == 0 {
            width: 100%;
        } @else {
            width: $global-width + $global-padding * 2;
        }
    }
}
