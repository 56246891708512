@mixin field-error {

    .#{$field-class-error} &,
    &.#{$field-class-error} {
        color: $field-error-color;

        @if $field-error-border-color != '' {
            border-color: $field-error-border-color;
        }

        @if $field-error-background != '' {
            background: $field-error-background;
        }
    }
}

.#{$field-class} {
    width: 100%;
    color: $field-color;
    border: $field-border-width $field-border-style $field-border-color;
    height: $field-height;
    font-family: $field-font-family;
    font-weight: $field-font-weight;
    font-size: $field-font-size;
    padding: 0 $field-padding;
    border-radius: $field-border-radius;
    background: $field-background;
    transition: border-color $button-transition-duration linear, background $button-transition-duration linear, color $button-transition-duration linear;

    &:focus {
        color: $field-focus-color;
        background: $field-focus-background;
        border-color: $field-focus-border-color;
    }

    &.ya-bg-white {
        background: white;
    }

    @include field-error;

    [data-trigger=spinner] & {
        text-align: center;
        font-size: 18px;
        color: #7a7a7a;
        border-top: none;
        border-bottom: none;
        border-right: none;
        border-radius: 0;
        padding: 0;

        .ya-view-basket-table-1 & {
            height: 26px;
            font-size: 15px;
        }
    }

    &.ya-theme-orange {
        background: $orange;
    }

    .ya-field-clear ~ &,
    .ya-field-clear ~ * & {
        padding-right: $field-height;
    }
}

textarea.#{$field-class} {
    height: $field-textarea-height;
    padding: $field-textarea-padding $field-padding;
}

::-webkit-input-placeholder {
    //color: $field-placeholder-color;
    //@include field-error;
}
::-moz-placeholder {
    //color: $field-placeholder-color;
    //@include field-error;
}
:-ms-input-placeholder {
    //color: $field-placeholder-color;
    //@include field-error;
}

@if $field-placeholder-hide-focus {

    .#{$field-class}::-webkit-input-placeholder       {opacity: 1; transition: opacity $field-placeholder-transition-duration ease;}
    .#{$field-class}::-moz-placeholder                {opacity: 1; transition: opacity $field-placeholder-transition-duration ease;}
    .#{$field-class}:-moz-placeholder                 {opacity: 1; transition: opacity $field-placeholder-transition-duration ease;}
    .#{$field-class}:-ms-input-placeholder            {opacity: 1; transition: opacity $field-placeholder-transition-duration ease;}
    .#{$field-class}:focus::-webkit-input-placeholder {opacity: 0; transition: opacity $field-placeholder-transition-duration ease;}
    .#{$field-class}:focus::-moz-placeholder          {opacity: 0; transition: opacity $field-placeholder-transition-duration ease;}
    .#{$field-class}:focus:-moz-placeholder           {opacity: 0; transition: opacity $field-placeholder-transition-duration ease;}
    .#{$field-class}:focus:-ms-input-placeholder      {opacity: 0; transition: opacity $field-placeholder-transition-duration ease;}
}

.#{$field-group-class} {
    display: flex;
    border: 1px solid white;
    border-radius: $field-border-radius;
    overflow: hidden;
    transition: all $global-transition-duration linear;

    &.ya-gray {
        border-color: #d7d7d7;
    }

    & > * {
        //flex: 1 1 0%;
        border-top: none;
        border-bottom: none;
        border-right: none;
        border-radius: 0;

        &:first-child {
            border-left: none;
            border-radius: $field-border-radius - 2 0 0 $field-border-radius - 2;
        }

        &:last-child {
            border-radius: 0 $field-border-radius - 2 $field-border-radius - 2 0;
        }

        &:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &.ya-width-auto > * {
        flex: 1 1 auto;
    }

    &.ya-error {
        border-color: $field-error-border-color;

        & > * {
            border-color: $field-error-border-color;
        }
    }

    .ya-spinner & {
        margin-bottom: 7px;
        @include cols(shrink auto shrink);

        .ya-view-basket-table-1 & {
            margin-bottom: 4px;
        }
    }
}

.ya-field-container {
    position: relative;

    &.ya-full {
        max-width: 100% !important;
        flex-basis: 100% !important;
    }

    .ya-group-checkbox & {
        flex: 1 1 0%;
    }

    .ya-form & {
        margin-bottom: 10px;
    }

    &.ya-width-md {
        width: 355px;
    }
}

.ya-form__attention {
    display: block;
    color: #3c3d41;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 7px;
}

.ya-checkbox {
    font-family: $font-main;
    cursor: pointer;
    @include cols(shrink default, 20px in, 'span');
    display: inline-flex;
    align-items: center;
    transition: color $global-transition-duration linear;

    &:hover {
        color: $anchor-color-hover;
    }
}

.ya-icon {

    &--checkbox {
        @include bg($checkbox);
        margin: 0 !important;

        input:checked + & {
            @include bg($checkbox-checked);
        }
    }
}

.ya-group-checkbox {
    @include cols(default, 20px in);
    justify-content: space-between;
}

.ya-group-checkbox__text {
    display: block;
    border: 1px solid #d5d5d5;
    border-radius: 18px;
    text-align: center;
    padding: 5px 0;
    font-size: 16px;
    cursor: pointer;
    transition: all $global-transition-duration linear;
    text-transform: lowercase;

    input:checked ~ &,
    &:hover {
        border-color: $anchor-color-hover;
        color: $anchor-color-hover;
        //background: $anchor-color-hover;
    }

    input:checked ~ & {
        cursor: default;
    }
}

.ya-field-label {
    color: #717275;
    display: inline-block;
    margin-bottom: 4px;
}

.ya-field-clear {
    position: absolute;
    z-index: $z-index-base;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    width: $field-height;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ya-form {
    margin-bottom: 50px;

    [type=submit] {
        display: block;
        margin: 30px 0;
    }
}
