.ya-subscription__row {
    @include cols(default, 13px);
    align-items: center;
}

.ya-subscription__form-row {
    @include cols((default shrink), 10px);
}

.ya-field {

    .ya-subscription__form & {
        width: 285px;
    }
}
