.ya-profiles {
    margin-bottom: 60px;
    overflow: hidden;
}

.ya-profiles__list-li {
    border-top: 1px solid #d7d7d7;
    position: relative;
    padding-top: 21px;
    margin-bottom: 43px;
}

.ya-profiles__list-delete {
    position: absolute;
    top: 22px;
    right: 5px;
}

.ya-profiles__title {
    display: block;
    color: #1b1b1b;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 18px;
}

.ya-profiles__list-options-li {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.ya-profiles__list-options-ul {
    @include cols(360px, 30px, 'li');
    flex-wrap: wrap;
    margin-bottom: 18px;
}

.ya-profiles__list-options-title {
    color: #717275;
    margin-bottom: 2px;
}

.ya-profiles__list-options-text {
    color: #1b1b1b;
    font-family: $font-helvetica;
    font-size: 18px;
}
