.ya-header {
    margin-bottom: 28px;
}

.ya-header__block {

    &--top {
        background-color: #f9f9f9;
        border-bottom: 1px solid #eee;
        padding: 10px 0;
    }

    &--middle {
        padding: 42px 0 43px;
    }

    &--bottom {
        background-color: #2e2e30;
        box-shadow: 0 3px 3px rgba(199, 199, 199, 0.6);
        color: white;
        position: relative;
        z-index: $z-index-hover + 1;
    }
}

.ya-header__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include gutter(20px);
}

.ya-header__row-bottom {
    @include cols(shrink auto);
}

.ya-col {

    .ya-header__row & {

        &.ya-shrink {
            flex-shrink: 0;
        }

        &.ya-grow {
            flex-grow: 1;
        }
    }

    .ya-header__fixed-row & {
        flex-shrink: 0;

        &.ya-grow {
            flex-grow: 1;
        }
    }
}

.ya-header__links-ul {
    @include cols(default, 20px, 'li');
}

.ya-header__a {
    text-decoration: none;
    color: #474747;
    font-family: $font-helvetica;
    transition: all $global-transition-duration linear;

    &:hover {
        color: #e2453f;
    }
}

.ya-header__fixed-row {
    @include cols(default, 46px in);
    justify-content: space-between;
    align-items: center;
}

.ya-header__fixed {
    //position: fixed;
    //top: 0;
    //left: 0;
    //min-width: 100%;
    //background-color: white;
    //box-shadow: 0 3px 3px rgba(199, 199, 199, 0.6);
    //padding: 13px 0;
}
