//.ya-basket-pay-info {
//    position: relative;
//
//    &:after {
//        content: '';
//        position: absolute;
//        top: 100%;
//        left: 0;
//        right: 0;
//        border: 10px solid transparent;
//        border-width: 10px 0;
//        //background-color: white;
//        //background-color: hsla(0, 0%, 0%, 0);
//        background-image:
//        linear-gradient(white, skyblue),
//        linear-gradient(to bottom right, transparent 50.5%, white 50.5%),
//        linear-gradient(to bottom left, transparent 50.5%, white 50.5%),
//        linear-gradient(to top right, transparent 50.5%, skyblue 50.5%),
//        linear-gradient(to top left, transparent 50.5%, skyblue 50.5%);
//        background-repeat: repeat, repeat-x, repeat-x, repeat-x, repeat-x;
//        background-position: 0 0, 10px 0, 10px 0, 10px 100%, 10px 100%;
//        background-size: auto auto, 20px 20px, 20px 20px, 20px 20px, 20px 20px;
//        background-clip: padding-box, border-box, border-box, border-box, border-box;
//        background-origin: padding-box, border-box, border-box, border-box, border-box;
//    }
//}

.ya-basket-pay-info__row {
    @include cols(default shrink, 20px in, 'span');
    justify-content: space-between;
    align-items: baseline;
    padding: 10px 0;
}

.ya-basket-pay-info__text {
    font-family: $font-helvetica;
    font-size: 12px;

    &.ya-price {
        font-size: 23px;
        font-weight: 700;
    }
}

.ya-currency {

    .ya-basket-pay-info__text & {
        font-family: $font-main;
        font-size: 20px;
        font-weight: 300;
    }
}
