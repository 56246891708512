.ya-block-text {
    background-color: #f8f8f8;
    padding: 30px 0;
}

p {

    .ya-block-text & {
        color: #747578;

        &:not(:last-child) {
            margin-bottom: 18px;
        }
    }
}
