@if $fixed-header {

    .#{$class-header-container} {
        height: $height-fixed-header;
    }

    .#{$class-fixed-header} {
        position: fixed;
        z-index: $z-index-fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: $height-fixed-header;
        min-width: $global-width + $global-padding * 2;
        background-color: white;
        box-shadow: 0 3px 3px rgba(199, 199, 199, 0.6);
        padding: 13px 0;
        transform: translateY(-120%);
        transition: transform .5s;

        &.ya-visible {
            transform: translateY(0);
        }
    }

}
