.ya-article__title {
    font-size: 26px;
    color: #1B1B1B;
    font-weight: 700;
    margin-bottom: 11px;
    display: block;
}

.ya-article__row {
    @include cols(default shrink, 20px in);
    justify-content: space-between;
    align-items: center;
    margin-bottom: 66px;
}

.ya-icon {

    .ya-article__info & {
        display: inline-block;
        margin: 0;
        vertical-align: top;
    }
}

.ya-article__info-text {
    color: #6c6c6e;
    font-size: 12px;
    font-weight: 700;
    margin-left: 3px;
}

%child {
    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.ya-article__text {
    font-family: $font-helvetica;
    font-size: 18px;
    color: #666D70;
    line-height: 1.6;
    margin-bottom: 50px;

    p {
        margin-bottom: 15px;
        @extend %child;
    }

    h2 {
        font-size: 22px;
        margin: 28px 0 15px;
    }

    h3 {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 3.18px;
        margin: 33px 0 20px;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 100%;
            margin-top: 3px;
            left: 0;
            background: $anchor-color;
            height: 1px;
            width: 70px;
        }
    }

    h4 {
        font-size: 12px;
        letter-spacing: 2.72px;
        font-weight: 700;
        margin: 34px 0 22px;
    }

    @for $i from 1 through 6 {
        h#{$i} {
            font-family: $font-main;
            color: #3B3D40;
            line-height: 1.3;
            @extend %child;
        }
    }

    ul,
    ol {
        margin-left: 78px;
        margin-bottom: 15px;
        @extend %child;

        li {
            padding-left: 24px;
            position: relative;
            @extend %child;
        }
    }

    ol {
        counter-reset: item;

        li {
            display: block;

            &:before {
                content: counter(item) ". ";
                counter-increment: item;
                color: $anchor-color-hover;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }

    ul {

        li {

            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 9px;
                background: $anchor-color;
                width: 4px;
                height: 4px;
                border-radius: 50%;
            }
        }
    }

    img {
        margin: 49px 0 51px;
        @extend %child;
    }

    blockquote {
        border-top: 1px solid #E3E3E3;
        border-bottom: 1px solid #E3E3E3;
        margin: 49px 0;
        padding: 28px 78px;
        font-family: $font-main;
        font-size: 32px;
        color: #3B3D40;
        line-height: 1.3;
        position: relative;
        @extend %child;

        &:after,
        &:before {
            content: '';
            position: absolute;
            top: -1px;
            bottom: -1px;
            width: 49px;
            border-top: 1px solid white;
            border-bottom: 1px solid white;
        }

        &:before {
            left: 29px;
        }

        &:after {
            right: 29px;
        }
    }

    table {
        width: 100%;
        table-layout: fixed;
        margin-bottom: 30px;
    }

    td,
    th {
        height: 44px;
        padding: 0 15px;
        text-align: left;
    }

    th {
        background: #2E2E30;
        border: 1px solid #626262;
        font-family: $font-main;
        font-weight: 700;
        font-size: 16px;
        color: #FFFFFF;
    }

    td {
        border: 1px solid #DDDDDD;
        color: #272727;
        font-size: 14px;

        &:first-child {
            font-family: $font-main;
            font-size: 16px;
            color: #080808;
            font-weight: 700;
        }
    }

    tr {

        &:nth-child(2n+2) td {
            background: #EFEFEF;
        }
    }

    a {
        color: $anchor-color;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }

        &:visited {
            color: #D31BE7;
        }
    }
}
