.ya-breadcrumbs {
    margin-top: -10px;
    margin-bottom: 18px;
}

.ya-breadcrumbs__li {
    display: inline;

    &:not(:last-child):after {
        content: '';
        margin: 0 2px 0 3px;
        display: inline-block;
        vertical-align: baseline;
        @include bg($pointer-right-2);
    }
}

.ya-breadcrumbs__text {
    vertical-align: middle;
    line-height: 1;
    border-bottom: 1px solid transparent;
    display: inline-block;
    font-family: $font-helvetica;
    font-size: 12px;
    color: #aaabac;

    &[href] {
        text-decoration: none;
        line-height: 0.9;
        color: $global-font-color;
        transition: all $global-transition-duration linear;

        &:hover {
            color: $anchor-color-hover;
            //border-color: $anchor-color-hover;
        }
    }
}
