.ya-contacts-info {

    .ya-footer & {

        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }
}

p {

    .ya-contacts-info__text & {
        font-size: 12px;

        .ya-footer & {
            font-size: 14px;
        }
    }

    .ya-contacts-info__text.ya-num & {
        font-size: 17px;
        font-weight: 700;

        .ya-footer & {
            font-weight: 400;
        }
    }
}

.ya-contacts-info__li {

    &.ya-row {
        @include cols((shrink default), 10px);
        align-items: center;
    }
}

.ya-contacts-info__icon {
    width: 34px;
    text-align: center;
}

.ya-icon {

    .ya-contacts-info__icon & {
        display: inline-block;
    }
}
