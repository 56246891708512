.ya-sidebar__block {
    background-color: #fcfcfc;
    border: 1px solid $sidebar-border-color;
    border-radius: 5px;
    margin-bottom: 23px;
    width: 230px;

    &:not(:first-child) {
        //margin-top: 23px;
    }

    .ya-sidebar--left & {
        margin-right: 20px;
    }

    .ya-sidebar--right & {
        margin-left: 20px;
    }

    .ya-card & {

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.ya-clear {
        background: none;
        border: none;
        border-radius: 0;
    }

    &.ya-width-lg {
        width: 245px;
    }
}

.ya-sidebar__title {
    display: block;
    padding: $sidebar-container-padding-y $sidebar-container-padding-x;
    color: #1b1b1b;
    font-size: 16px;
    font-weight: 700;
    background-color: #eee;
}

.ya-sidebar__container {
    padding: 0 $sidebar-container-padding-x;

    & + & {
        border-top: 1px solid $sidebar-border-color;
    }

    &.ya-border-top {
        border-top: 1px solid $sidebar-border-color;
    }
}

.ya-sidebar__container-in {
    padding-bottom: 20px;

    &.ya-padding-top {
        padding-top: 20px;
    }
}

.ya-content__row {
    @include cols(shrink auto shrink);
}

.ya-content__main {
    max-width: 100%;
    min-width: 0%;
    flex-basis: 0% !important;
}

.ya-col {

    .ya-content__row > &[class*=ya-sidebar] {
        overflow: hidden;
    }
}
