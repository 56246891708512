$border-color-free: #ffdc17;
$border-color: #e7221b;

%offer-container {
    padding-top: 23px;
    padding-bottom: 23px;
}

.ya-offer {
    padding-right: 30px;
    border: 1px solid #e7221b;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 23px;
    }

    &.ya-free {
        border-color: $border-color-free;
    }
}

.ya-offer__row {
    @include cols(default, 30px in);
}

.ya-col {

    .ya-offer__row & {

        &.ya-buttons {
            width: 200px;
            flex-basis: 200px;
            flex-shrink: 0;
            @extend %offer-container;
            display: flex;
            align-items: center;
        }

        &.ya-price {
            width: 195px;
            flex-basis: 195px;
            flex-shrink: 0;
            @extend %offer-container;
            position: relative;
            display: flex;
            align-items: center;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                width: 1px;
                top: 10px;
                bottom: 10px;
                background: #d7d7d7;
            }
        }

        &.ya-img {
            flex-grow: 2;
            flex-basis: 0%;
            display: flex;
        }

        &.ya-text {
            flex-grow: 3;
            flex-basis: 0%;
            @extend %offer-container;
        }
    }

    .ya-offer__form-row & {

        &.ya-right {
            width: 399px;
        }
    }
}

.ya-offer__img {
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid $border-color;

    .ya-offer.ya-free & {
        border-color: $border-color-free;
    }
}

.ya-offer__img-in {
    max-width: 100%;
}

.ya-button {

    .ya-offer .ya-col.ya-buttons & + & {
        margin-top: 12px;
    }
}

.ya-offer__title {
    display: block;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 7px;
}

.ya-offer__price-container {

    & + & {
        margin-top: 10px;
    }
}

.ya-offer__price-text {
    font-family: $font-helvetica;
    font-size: 12px;
}

.ya-offer__price-num {
    font-size: 23px;
    font-weight: 700;
    margin-left: 8px;
}

.ya-currency {

    .ya-offer__price-num & {
        font-size: 20px;
        font-weight: 300;
    }
}

p {

    .ya-offer .ya-text & {
        line-height: 1.45;
    }
}

.datepick-container-in {
    position: relative;
}

.ya-offer__form-row {
    @include cols(default, 30px in);
}

.ya-field-container {

    .ya-offer__form & {
        margin-bottom: 20px;
    }
}

textarea {

    .ya-offer__form & {
        height: 170px;
    }
}
