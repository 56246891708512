.ya-delivery__show-map {
    opacity: 0;

    tr:hover & {
        opacity: 1;
    }

    .ya-page.ya-is-progress & {
        visibility: hidden;
    }
}

.ya-delivery__find-row {
    @include cols(default, 15px);
}

.ya-delivery__destination {
    transition: all $global-transition-duration linear;

    &.ya-mark {
        font-weight: 700;
        color: $anchor-color-hover;
    }
}

.ya-typeahead {

    .ya-delivery__find & {
        width: 214px;
    }
}

.ya-delivery__find {
    margin-bottom: 43px;
}

td {

    .ya-delivery__table & {

        &:first-child {
            font-weight: 700;
        }

        &:nth-child(2) {
            font-size: 13px;
        }
    }
}

$map-height: 500px;

.ya-delivery__map {
    padding-top: 20px;
}

#ya-delivery-map {
    width: 100%;
    height: $map-height;
}

table.ya-delivery__table {
    margin-bottom: 44px;
}
