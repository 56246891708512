// шрифты

$font-main: "Circe";
$font-helvetica: "Helvetica";
//$font-rouble: 'PTRouble';

@include font-face-old($font-main, "CirceLight", 300);
@include font-face-old($font-main, "Circe");
@include font-face-old($font-main, "CirceBold", 700);

@include font-face-old($font-helvetica, "HelveticaRegular");
@include font-face-old($font-helvetica, "HelveticaBold", 700);

//@include font-face-old($font-rouble, "PTRoubleSans");
