.ya-docs {
    margin-bottom: 20px;
}

.ya-account__table-sort {
    text-decoration: none;
    color: inherit;
    transition: color $global-transition-duration linear;

    &:after {
        content: '';
        display: inline-block;
        margin-left: 4px;
        border: 7px solid transparent;
        transition: border-color $global-transition-duration linear;
    }

    &.ya-up {

        &:after {
            border-bottom-color: #b8bebf;
            border-top-width: 0;
        }

        &:hover {

            &:after {
                border-bottom-color: $anchor-color-hover;
            }
        }
    }

    &.ya-down {

        &:after {
            border-top-color: #b8bebf;
            border-bottom-width: 0;
        }

        &:hover {

            &:after {
                border-top-color: $anchor-color-hover;
            }
        }
    }

    &:hover {
        color: $anchor-color-hover;
    }
}

.ya-account__table-download {
    text-transform: uppercase;
    color: #f9c5c3;
    font-size: 13px;
    text-decoration: underline;
    transition: color $global-transition-duration linear;

    &:hover {
        color: #ec7e7a;
    }
}
