@import "ya-slider-core";
$width-slider-card: 382px;

// главный контейнер для слайдера
.ya-slider-container {

    &--ad {
        overflow: hidden;

        .ya-content__row & {
            margin-bottom: 24px;
            border: 1px solid #e8e8e8;
            border-radius: 3px;
            overflow: hidden;
            position: relative;
            z-index: $z-index-base;
        }
    }

    &--card {
        width: $width-slider-card;
        margin-bottom: 10px;
    }

    &--card-nav {
        width: $width-slider-card;
    }
}

// настройки обертки для слайдов (ul)
.ya-slider__wrapper {

    .ya-slider-container--ad & {
        @include cols(1, 0, 'li');
        // height: 260px; // для эффекта fade
        height: auto !important;

        // для кастомных эффектов
        // ----------------------
        //transition: background 0s linear 0.3s;
        //background: #eeeeee; // цвет фона чтобы не видны были элементы других слайдов
    }

    .ya-slider-container--card & {
        @include cols(1, 0, 'li');
         height: 380px; // для эффекта fade
    }

    .ya-slider-container--card-nav & {
        @include cols(5, 8px, 'li', 'true');
        height: 70px; // для эффекта fade
    }

    .ya-slider-container--card-nav.ya-slider-small & {
        justify-content: flex-start;
    }

    // для кастомных эффектов
    // ----------------------
    .ya-slider-container--reviews.ya-slider-ready & {
        background: none;
    }

    .ya-slider-container--nav & {
        @include cols(25%, 20px, 'li', 'true');
    }
}

// настройки слайдов (li)
.ya-slider__slide {

    .ya-slider-container--ad & {
        height: auto;
    }
}

// настройки обертки контента слайдов (li > div)
.ya-slider__slide-in {

    [class*=ya-slider-container--card] & {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .ya-slider-container--card-nav & {
        border: 1px solid transparent;
        //transition: all $global-transition-duration linear;
        position: relative;
    }

    .ya-slider-container--card-nav .ya-slide-active &,
    .ya-slider-container--card-nav &:hover {
        border-color: #dfdfdf;
        background-color: #f4f4f4;
    }

    .ya-slider-container--card-nav .ya-slide-active & {
        cursor: default;
    }


    //.ya-slider-container--nav & {
    //    position: relative;
    //
    //    &:after {
    //        content: '';
    //        position: absolute;
    //        top: 0;
    //        right: 0;
    //        bottom: 0;
    //        left: 0;
    //        border: 3px solid transparent;
    //    }
    //}
    //
    //.ya-slider-container--nav .ya-slide-active & {
    //
    //    &:after {
    //        border-color: blue;
    //    }
    //}
}

// точки

.ya-slider__dots {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
}

.ya-slider__dots-ul {
    @include cols(default, 13px in, 'li');
}

.ya-slider__dots-button {
    display: block;
    width: 16px;
    height: 16px;
    opacity: 0.5;
    background-color: #9a9a9a;
    border-radius: 50%;
    transition: all $global-transition-duration linear;

    &:hover {
        opacity: 1;
    }

    &.ya-slide-active {
        opacity: 1;
    }
}

// настройки кнопок
.ya-slider__button {
    width: 72px;
    height: 72px;
    opacity: 0.45 !important;
    background-color: #ededed;
    //border: 1px solid #ededed;
    border-radius: 50%;
    transition: all $global-transition-duration linear;
    pointer-events: auto;

    &.ya-sm {
        width: 41px;
        height: 41px;
    }

    &:hover {
        background-color: #e7221b;
    }

    &:active {
        background-color: #e7221b - 20%;
    }

    &.ya-slider-disable {
        background-color: #9a9a9a;
        cursor: default;
    }

    [class*=ya-slider-container--card] & {
        position: absolute;
        z-index: $z-index-base + 1;
        top: 50%;
        transform: translateY(-50%);

        &--prev {
            left: 0;
        }

        &--next {
            right: 0;
        }
    }

    @include vertical-align('.ya-icon');
}

// для счетчика слайдов
.ya-slider__counter {

}

// для кастомных эффектов
.ya-slider-custom-anim {
    transition: all 0s linear;

    .ya-slider-ready & {
        opacity: 0;
        transition: all 0.3s linear;
    }

    .ya-slide-active & {
        opacity: 1;
    }
}

.ya-slider-custom-anim-no {
    @extend .ya-slider-custom-anim;

    transition: all 0s linear;
}

.ya-slider__a {
    display: block;
}

.ya-slider__buttons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    display: flex;
    justify-content: space-between;
    pointer-events: none;

    .ya-content__row & {
        padding: 0 26px;
    }
}

img {

    .ya-slider__img & {
        width: 100%;
    }
}
