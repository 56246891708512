.ya-selection {
    margin-bottom: 30px;

    &.ya-section {
        background: #ececec url("__static__general/bg-selection.png");
        box-shadow: inset 0 -3px 5px rgba(199, 199, 199, 0.6), inset 0 3px 5px rgba(199, 199, 199, 0.6);
        margin-bottom: 0;
    }
}

.ya-selection__content {
    //margin: 0 -20px;
    border: 1px solid #e1e1e1;
    border-radius: 5px;

    .ya-selection.ya-section & {
        background-color: white;
        margin: 0;
    }
}

.ya-selection__row {
    display: flex;
    min-height: 243px;
}

.ya-selection__steps-block {
    flex-grow: 1;
    flex-basis: 0%;
    display: flex;
    padding: 17px 0;
    position: relative;

    & + & {
        border-left: 1px solid #eeeeee;
    }

    &.ya-no-padding-bottom {
        padding-bottom: 0;
    }
}

.ya-selection__steps-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0 5px 5px 0;
    padding: 20px;
    display: flex;
}

.ya-selection__steps-overlay-content {
    background: rgba(#050505, .7);
    border-radius: 5px;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
}

.ya-selection__steps-overlay-title {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 10px;
}

.ya-selection__steps-overlay-text {

}

.ya-selection__container {
    margin: 0 20px;

    .ya-selection__steps-block & {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

.ya-selection__steps-res {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
}

.ya-selection__steps-cat {

    &.ya-full {
        border-top: 1px solid #eeeeee;
        padding: 17px 0 13px;
        display: none;
    }
}

.ya-selection__steps-cat-ul {
    margin-top: -3px;

    .ya-selection__steps-cat.ya-full & {
        @include cols(4, 0, 'li');
        flex-wrap: wrap;
    }
}

.ya-selection__steps-cat-li {
    margin-bottom: 1px;
}

.ya-selection__title {
    display: block;
    color: #838c8e;
    font-weight: 700;
    margin-bottom: 15px;

    &.ya-dark {
        color: black;
    }
}

.ya-selection__steps-res-text {
    color: #3c3d41;
    font-size: 42px;
    font-weight: 700;
}

.ya-field-container {

    .ya-selection__steps-block & + & {
        margin-top: 20px;
    }
}

.ya-button {

    .ya-selection__steps-block .ya-field-container + & {
        margin: 25px 0 15px;
        align-self: flex-start;
    }
}

.ya-selection__steps-cat-a {
    font-family: $font-helvetica;
    text-decoration: none;
    color: #3c3d41;
    transition: all $global-transition-duration linear;
    display: inline-block;

    &:hover {
        color: $anchor-color-hover;
    }

    .ya-selection__steps-cat.ya-full & {
        @extend .ya-selection__container;
    }
}

.ya-selection__steps-cat-toggle {
    font-family: $font-helvetica;
    color: #e7221b;
    text-decoration: none;
    border-bottom: 1px dotted #e7221b;
    transition: all $global-transition-duration linear;

    &:hover {
        border-color: transparent;
    }
}

.tt-menu {
    max-height: 150px;
    overflow-y: auto;
    z-index: $z-index-hover !important;
}

.twitter-typeahead {
    width: 100%;
    //z-index: $z-index-hover;
}

.typeahead,
.tt-query,
.tt-hint {
    width: 100%;
}

.tt-query {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
    color: #999
}

.tt-menu {
    width: 100%;
    padding: 8px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

.tt-suggestion {
    padding: 3px 20px;
}

.tt-suggestion:hover {
    cursor: pointer;
    color: #fff;
    background-color: $anchor-color;
}

.tt-suggestion.tt-cursor {
    color: #fff;
    background-color: $anchor-color;

}

.tt-suggestion p {
    margin: 0;
}
