$table-padding-x: 10px;

.ya-account__table {
    width: 100%;
    table-layout: fixed;
    font-family: $font-helvetica;
    margin-bottom: 12px;
}

th {

    .ya-account__table & {
        color: #bdbdbd;
        font-weight: 700;
        border-bottom: 1px solid #d7d7d7;
        padding: 0 $table-padding-x 8px;
        text-align: left;
    }

    .ya-account__table.ya-last-right & {

        &:last-child {
            text-align: right;
        }
    }

    .ya-account__table.ya-orders-info & {
        text-align: center;

        &:first-child {
            text-align: left;
        }
    }
}

td {

    .ya-account__table & {
        padding: 11px $table-padding-x 10px;
        border-bottom: 1px solid #ebebeb;
        transition: background $global-transition-duration linear;
    }

    .ya-account__table.ya-last-right & {

        &:last-child {
            text-align: right;
        }
    }

    .ya-account__table.ya-mark-tr tr:hover & {
        background-color: #f3f3f3;
    }

    .ya-account__table.ya-orders-info & {
        font-size: 16px;
        font-family: $font-main;
        text-align: center;
        border-left: 1px solid;
        border-color: #d7d7d7;

        &:first-child {
            text-align: left;
            border-left: none;
        }
    }

    .ya-account__table.ya-orders-info tr:last-child & {
        border-color: transparent;

        &:first-child {
            color: #191919;
            font-size: 18px;
            font-weight: 700;
        }
    }
}

.ya-bonus-prog__empty {
    text-align: center;
    color: #899394;
    padding: 86px 0;
    margin-bottom: 20px;
}

.ya-bonus-prog__empty-title {
    display: block;
    font-size: 23px;
    margin-bottom: 28px;
}

.ya-bonus-prog__empty-text {
    font-size: 18px;
}

.ya-account__table-price {
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
}

.ya-currency {

    .ya-account__table-price & {
        font-family: $font-main;
    }
}
