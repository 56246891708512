$li-margin-bottom: 50px;
$li-margin-bottom-content-main: 30px;
$li-margin-bottom-view-table: 20px;
$details-transition-duration: .1s;
$col-width-view-table: 275px;
$container-padding-y-view-table-1: 30px;
$container-padding-y-view-table-2: 14px;

.ya-list-products {
    //overflow: hidden;

    .ya-content__main & {
        margin-bottom: 32px;
    }
}

.ya-list-products__ul {
    @include cols(4, 26px, 'li');
    flex-wrap: wrap;
    margin-bottom: 0 - $li-margin-bottom;

    .remodal & {
        @include cols(4, 10px, 'li');
    }

    &.ya-cols-num-3 {
        margin-bottom: 0 - $li-margin-bottom-content-main;
        @include cols(3, 37px, 'li');
    }

    [class*=ya-view-table] & {
        @include cols(1, 0px, 'li');
        margin-bottom: 0 - $li-margin-bottom-view-table;
    }
}

.ya-list-products__li {
    display: flex;
    margin-bottom: $li-margin-bottom;

    &.ya-set {
        max-width: 50%;
        flex: 0 0 50%;

        .ya-content__main & {
            max-width: 66.66666%;
            flex: 0 0 66.66666%;
        }

        [class*=ya-view-table] & {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }

    .ya-cols-num-3 & {
        margin-bottom: $li-margin-bottom-content-main;
    }

    [class*=ya-view-table] & {
        margin-bottom: $li-margin-bottom-view-table;
    }
}

.ya-list-products__li-in {
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: $z-index-base;

    .ya-view-table-1 & {
        flex-direction: row;
        @include cols(66.66666% 33.33333%);
    }

    .ya-view-table-2 & {
        flex-direction: row;
        @include cols(60% 40%);
        padding: $container-padding-y-view-table-2 0;
    }

    &.ya-sum {
        background-color: #fcfcfc;
        border-style: dashed;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }
}

.ya-list-products__sum-container {

    & + & {

    }
}

.ya-list-products__li-top {
    flex-grow: 1;
    padding: 18px 0 11px;
    position: relative;

    .ya-view-table-1 & {
        @include cols(2);
        padding: 0;
    }

    .ya-view-table-2 & {
        @include cols(shrink auto);
        padding: 0;
    }
}

.ya-list-products__li-container {
    padding: 0 21px;

    .ya-list-products__li-bottom &:not(.ya-visible-view-table) + & {
        border-top: 1px solid #dfdfdf;
    }

    [class*=ya-view-table] .ya-list-products__li-bottom & {
        border-top: none !important;
    }

    .ya-list-products__details & {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    [class*=ya-view-table] & {
        margin: 0;
        padding: 0 37px;

        &.ya-img {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .ya-view-table-1 .ya-list-products__li-top & {
        padding-top: $container-padding-y-view-table-1;
        padding-bottom: $container-padding-y-view-table-1;
    }

    .ya-view-table-1 .ya-list-products__li-top & + & {
        border-left: 1px solid #dfdfdf;
    }

    &.ya-bg-white {
        background: white;
        border-radius: 0 0 4px 4px;
    }

    .ya-view-table-2 & {
        padding: 0 16px;

        &.ya-name {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-left: 0;
            width: 100%;
        }

        &.ya-price {
            border-left: 1px solid #dfdfdf;
        }
    }

    .ya-list-products__sum & {
        position: relative;
        padding-top: 12px;
        padding-bottom: 12px;

        &:not(:first-child):before {
            content: '';
            position: absolute;
            top: 0;
            left: 21px;
            right: 21px;
            border-top: 1px solid #dfdfdf;
        }
    }
}

.ya-list-products__sum {
    width: 100%;
}

.ya-list-products__sum-text {
    color: #0d0d0d;
    font-size: 20px;
    display: block;

    &.ya-lg {
        color: #3c3d41;
        font-size: 83px;
        font-weight: 300;
        margin-bottom: -10px;
    }

    &.ya-margin-md {
        margin-bottom: 22px;
    }
}

.ya-list-products__img {
    display: block;
    height: 204px;
    width: 100%;
    position: relative;
    text-decoration: none;
    margin-bottom: 18px;
    text-align: center;

    [class*=ya-view-table] & {
        margin-bottom: 0;
    }

    .ya-view-table-2 & {
        height: 97px;
        width: 97px;
    }
}

img {
    .ya-list-products__img & {
        max-height: 100%;
    }
}

%badge {
    border-radius: 5px;
    padding: 1px 8px 2px;
}

.ya-list-products__badge  {
    @extend %badge;
    background-color: #48a841;
    color: white;
    position: absolute;
    top: -5px;
    right: -5px;

    &.ya-circle {
        right: auto;
        left: -7px;
        width: 65px;
        height: 65px;
        background-color: #ffe719;
        color: #e7221b;
        padding: 0;
        text-align: center;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 0.9;

        b {
            font-size: 20px;
            font-weight: 700;
        }
    }

    .ya-list-products__badge-container & {
        position: static;
        display: inline-flex !important;

        &.ya-circle {
            width: auto;
            height: auto;
            @extend %badge;
            display: inline-block !important;
            line-height: inherit;

            b {
                font-size: 14px;
            }
        }
    }

    .ya-list-products__badge-container & + & {
        margin-left: 5px;
    }
}

.ya-list-products__name {
    color: #0d0d0d;
    font-size: 18px;
    text-decoration: none;
    line-height: 1.25;
    transition: all $global-transition-duration linear;

    &:hover {
        color: $anchor-color-hover;
    }
}

.ya-list-products__li-row {
    @include cols(default shrink, 10px in);
    align-items: flex-end;
    justify-content: space-between;

    &--price {
        padding: 15px 0;
        border-top: 1px solid #dfdfdf;

        .ya-view-table-2 & {
            @include cols(1, 0px);
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
            border-top: none;
            padding: 0;
        }
    }

    .ya-card__sidebar-container &--price {
        border-top: none;
        padding: 0;
    }
}

.ya-list-products__old-price {
    display: inline-block;
    color: #8a8b8d;
    font-size: 16px;
    position: relative;
    line-height: 1;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        height: 1px;
        background: #e24a44;
        margin-top: -1px;
    }

    .ya-basket-table__table & {
        margin-bottom: 7px;
    }

    .ya-view-basket-table-1 .ya-basket-table__table & {
        margin-bottom: 4px;
    }

    .ya-orders-info & {
        font-size: 16px;
    }
}

.ya-currency {

    .ya-list-products__old-price & {
        font-size: 14px;
        font-weight: 300;
    }

    .ya-list-products__price & {
        font-weight: 300;
        font-size: 20px;

        .ya-basket-table__table & {
            font-size: 16px;
        }

        .ya-spinner & {
            font-size: 14px;
            color: #414141;
        }
    }

    .ya-list-products__bonus-num & {
        font-size: 14px;
        font-weight: 300;
    }

    .ya-list-products__details-price & {
        font-size: 20px;
        font-weight: 300;
    }

    .ya-orders-info & {
        font-size: 16px;
    }

    .ya-orders-info .ya-list-products__old-price & {
        font-size: 14px;
    }
}

.ya-list-products__price {
    display: block;
    color: #191919;
    font-size: 23px;
    font-weight: 700;
    line-height: 1;

    .ya-list-products__li &,
    .ya-list-products__li-row--price & {
        position: relative;
        top: 5px;
    }

    .ya-view-table-2 & {
        margin-bottom: 18px;

        &:first-child {
            margin-top: 19px;
        }
    }

    .ya-basket-table__table & {
        font-size: 18px;
    }

    .ya-spinner & {
        display: inline-block;
        top: 0;
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .ya-orders-info & {
        font-size: 18px;
    }
}

.ya-list-products__bonus {
    color: #3e3e3e;
    padding: 13px 0 14px;
    border-radius: 0 0 5px 5px;

    .ya-view-table-1 & {
        border-top: 1px solid #dfdfdf;
        padding-bottom: 0;
    }

    .ya-card__sidebar-container & {
        padding: 0;
    }
}

.ya-list-products__bonus-title {
    display: block;
    font-size: 16px;
}

.ya-list-products__bonus-num {
    background-color: #e6e6e6;
    border-radius: 3px;
    padding: 3px 8px 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
    margin-right: 7px;
}

.ya-list-products__details {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    border-radius: 5px 5px 0 0;
    color: #131313;
    background: rgba(249, 249, 249, .97);
    padding-top: 32px;
    transition: all $details-transition-duration linear;
    opacity: 0;
    visibility: hidden;

    .ya-details-visible & {
        opacity: 1;
        visibility: visible;
    }
}

.ya-list-products__details-close {
    position: absolute;
    right: 20px;
    top: 20px;
}

.ya-list-products__details-title {
    display: block;
    font-size: 23px;
    margin-bottom: 22px;
    flex-shrink: 0;
}

.ya-list-products__details-text {
    font-family: $font-helvetica;
    color: inherit;
    text-decoration: none;
    line-height: 1.2;
    transition: all $global-transition-duration linear;

    &[href]:hover {
        color: $anchor-color-hover;
    }
}

$details-list-hidden-right: 50px;
.ya-list-products__details-list {
    flex-grow: 1;
    overflow-y: auto;
    width: calc(100% + #{$details-list-hidden-right});
}

.ya-list-products__details-ul {
    margin-right: #{$details-list-hidden-right};
}

.ya-list-products__details-row {
    @include cols(300px shrink, 10px in, '.ya-col');
    justify-content: space-between;
    align-items: flex-end;
    border-top: 1px solid #ededed;
    padding: 9px 0 5px;
}

.ya-list-products__details-price {
    color: #181818;
    font-size: 23px;
    font-weight: 700;
    line-height: 1;
}

.ya-list-products__details-sum {
    padding: 15px 0 7px;
    flex-shrink: 0;
}

.ya-show-details {

    .ya-details-visible & {
        display: none;
    }
}

.ya-show-card {

    li:not(.ya-details-visible) & {
        display: none;
    }
}

.ya-button {

    &--set {
        margin-right: 9px;
    }
}

.ya-list-products__li-bottom {
    transition: background $details-transition-duration linear;

    .ya-details-visible & {
        background-color: #f9f9f9;
    }

    [class*=ya-view-table] & {
        border-left: 1px solid #dfdfdf;
        padding: 23px 0;
    }

    .ya-view-table-2 & {
        @include cols(58% 42%);
        padding: 5px 0 0;
    }
}

.ya-list-products__sort-row {
    @include cols(default, 20px in);
    justify-content: space-between;
    align-items: center;
}

.ya-list-products__sort {
    background-color: #fcfcfc;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 3px;
    margin-bottom: 18px;
}

.ya-list-products__li-text {
    display: none;

    [class*=ya-view-table-1] & {
        display: block;
        margin: 22px 0 10px;
    }
}

.ya-visible-view-table {
    display: none;

    [class*=ya-view-table] & {
        display: block;
    }
}

.ya-visible-view-table-2 {
    display: none;

    .ya-view-table-2 & {
        display: block;
    }
}

.ya-hidden-view-table {

    [class*=ya-view-table] & {
        display: none;
    }
}

.ya-hidden-view-table-2 {

    .ya-view-table-2 & {
        display: none;
    }
}

.ya-spinner__text {
    color: #99999b;
    display: inline-block;
    margin-bottom: 5px;
}

.ya-spinner {

    .ya-list-products & {
        margin-bottom: 5px;
    }
}

.ya-list-products__img-container {
    width: 100%;
    height: 100%;
    @include vertical-align('img');
}



