.ya-basket-info {
    position: relative;
    margin-right: 8px;
}

.ya-basket-info__a {
    color: inherit;
    text-decoration: none;
}

.ya-basket-info__num {
    position: absolute;
    top: 0;
    right: 0;
    background: #82d889;
    border-radius: 5px;
    padding: 2px 2px;
    min-width: 21px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    transform: translateX(8px) translateY(-8px);
}
