.#{$button-class} {

    // основные стили //

    display: inline-block;
    padding: 0 $button-padding;
    line-height: $button-height - $button-border-width * 2;
    width: $button-width;
    vertical-align: middle;
    transition: border-color $button-transition-duration linear, background $button-transition-duration linear, color $button-transition-duration linear, opacity $button-transition-duration linear;
    font-size: $button-font-size;
    border-radius: $button-radius;
    text-decoration: none;
    text-transform: $button-text-transform;
    font-family: $button-font-family;
    font-weight: $button-font-weight;
    text-align: center;

    @if $button-border-width != 0 {
        border: $button-border-width $button-border-style transparent;
    }

    &[disabled] {
        filter: grayscale(100%);
        cursor: default;
        pointer-events: none;
    }

    &:active {
        transition: all $button-active-transition-duration linear;
    }

    &.ya-lg {
        line-height: 44px - $button-border-width * 2;
    }

    &.ya-sm {
        line-height: 27px - $button-border-width * 2;
    }

    &.ya-ellipse {
        border-radius: 14px;
    }

    &.ya-full {
        width: 100%;
    }

    &.ya-width-md {
        width: 105px;
    }

    .#{$field-group-class} & {
        padding: 0 10px;
        min-width: 48px;
    }

    [data-trigger=spinner] & {
        font-size: 23px;
        font-weight: 700;

        .ya-view-basket-table-1 & {
            line-height: 26px - $button-border-width * 2;
            height: 26px;
        }
    }



    // набор кнопок //

    &--red {
        @include button-color(white);
        @include button-background(#e7221b, #e7221b - 20%);
        @include button-border-color(#e7221b, #e7221b - 20%);
    }

    &--red-transparent {
        @include button-color(#e7221b, white);
        @include button-background(transparent, #e7221b - 20%);
        @include button-border-color(#e7221b, #e7221b - 20%);
    }

    &--gray-transparent {
        @include button-color(#b6b6b7, white);
        @include button-background(transparent, #e7221b - 20%);
        @include button-border-color(#b6b6b7, #e7221b - 20%);
    }

    &--gray-transparent-dark {
        @include button-color(#7a7a7a, white);
        @include button-background(transparent, #e7221b - 20%);
        @include button-border-color(#aaa, #e7221b - 20%);
    }

    &--gray {
        @include button-color(#959597, #3c3d41);
        @include button-background(#eeeeee, white);
        @include button-border-color(#d7d7d7);

        &.ya-active {
            background: white;
            color: #3c3d41;
        }
    }

    &--circle {
        @include button-background(transparent, #e7221b - 20%);
        @include button-border-color(#eee, #e7221b - 20%);
        padding: 0;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        line-height: 1;
    }

    &--circle-transparent {
        @include button-background(transparent);
        @include button-border-color(transparent);
        padding: 0;
        border-radius: 50%;
        width: 19px;
        height: 19px;
        line-height: 1;
    }

    &--green {
        @include button-color(white);
        @include button-background(#3ca773, #3ca773 - 20%);
        @include button-border-color(#3ca773, #3ca773 - 20%);
    }

    &--green-transparent {
        @include button-color(#3ca773, white);
        @include button-background(transparent, #3ca773 - 20%);
        @include button-border-color(#3ca773, #3ca773 - 20%);
    }

    &--yellow {
        @include button-color(#3c3d41);
        @include button-background(#fedc1a, #fedc1a - 20%);
        @include button-border-color(#caad00);
    }
}

.ya-button__text {
    vertical-align: middle;
    line-height: normal;

    .ya-icon + & {
        margin-left: 5px;
    }

    [data-trigger=spinner] & {
        line-height: inherit;
    }
}
