$remodal-class-hidden-overlay: 'remodal-overlay-none';

$remodal-animation-duration: 0.4s;

$remodal-overlay-background: rgba(224, 224, 224, .5) url("__static__general/bg-modal.png");
$remodal-overlay-open-duration: $remodal-animation-duration;
$remodal-overlay-close-duration: $remodal-animation-duration;

$remodal-wrapper-padding: 10px; // отступы по краям экрана

$remodal-width: auto;
$remodal-min-width: 0;
$remodal-max-width: auto;
$remodal-background: white;
$remodal-border-radius: 5px;
$remodal-padding: 25px 40px 45px;
$remodal-color: inherit;

$remodal-container-width: auto;
$remodal-container-min-width: 0;
$remodal-container-max-width: auto;

$remodal-open-animation-name: remodal-open;
$remodal-open-duration: $remodal-animation-duration;
$remodal-close-animation-name: remodal-close;
$remodal-close-duration: $remodal-animation-duration;

@import 'remodal-custom';
@import "remodal-theme-base";

// подложка
.remodal-overlay {

}

// контейнер окна
.remodal-wrapper {

}

// окно
.remodal {

    &--offcanvas-right {

    }

    &--offcanvas-left {

    }

    &--offcanvas-top {

    }

    &--offcanvas-bottom {

    }
}

// контейнер для содержимого окна
.remodal__container {
    width: 400px;

    .remodal.ya-width-lg & {
        width: 848px;
    }

    .remodal.ya-width-xlg & {
        width: $global-width - 80px;
    }

    &.ya-width-auto {
        width: auto;
        display: inline-block;
    }
}

.remodal__title {
    display: block;
    color: #1b1b1b;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 21px;
}

.remodal-close {
    position: absolute;
    top: 11px;
    right: 11px;
}
