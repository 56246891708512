.ya-list-news {

    .ya-content__main & {
        margin-bottom: 34px;
    }

    [class*=ya-sidebar] & {
        margin-bottom: 0;
        padding: 0;
    }
}

$li-margin-bottom: 30px;

.ya-list-news__ul {
    @include cols(4, 20px, 'li');
    flex-wrap: wrap;
    margin-bottom: 0 - $li-margin-bottom;

    [class*=ya-sidebar] & {
        display: block;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-bottom: 0;
    }
}

.ya-list-news__li {
    display: flex;
    margin-bottom: $li-margin-bottom;

    [class*=ya-sidebar] & {
        padding: 0;
        max-width: 100%;
    }
}

.ya-list-news__li-container {
    background-color: #3c3d41;
    color: #bbb;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ya-list-news__li-top {
    flex-grow: 1;
}

.ya-list-news__img {
    display: block;
    margin-bottom: 25px;
}

img {

    .ya-list-news__img & {
        width: 100%;
        height: 140px;
    }
}

.ya-list-news__container {
    margin: 0 23px;
}

.ya-list-news__title {
    color: white;
    font-family: $font-helvetica;
    text-decoration: none;
    font-weight: 700;
    display: inline-block;
    transition: all $global-transition-duration linear;
    margin-bottom: 6px;

    &:hover {
        color: $anchor-color-hover;
    }
}

.ya-list-news__li-bottom {
    border-top: 1px solid #59595d;
    padding: 12px 0 11px;
}

.ya-list-news__row {
    @include cols(default, 10px in);
    justify-content: space-between;
    align-items: center;
}

.ya-list-news__link {
    display: flex;
    align-items: center;
    color: #b8c6c7;
    font-family: $font-helvetica;
    text-decoration: none;
    font-size: 13px;
    transition: all $global-transition-duration linear;

    &:hover {
        color: $anchor-color-hover;
    }
}

.ya-icon {

    .ya-list-news__link & {
        flex-shrink: 0;
        margin-left: 8px;
    }

    .ya-list-news__more-icon & {
        margin: 0;
    }
}

.ya-list-news__date {
    color: #b8c6c7;
    font-size: 13px;
    text-transform: uppercase;
}

.ya-list-news__text {
    margin-bottom: 17px;
}

.ya-list-news__more {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 39px 0 0;
}

.ya-list-news__more-icon {
    width: 44px;
    height: 44px;
    border: 2px solid #2e2e30;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all $global-transition-duration linear;
    margin-bottom: 17px;

    &:hover {
        border-color: $anchor-color-hover;
        background: $anchor-color-hover;
    }
}

.ya-list-news__more-text {
    color: #2e2e30;
    font-family: $font-helvetica;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.5;
}

