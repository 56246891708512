$border-color: #76777a;

.ya-social__ul {
    display: flex;
    border: 1px solid $border-color;
    border-radius: 4px;
    overflow: hidden;
}

.ya-social__li {
    display: flex;
    align-items: center;
    border-left: 1px solid $border-color;

    &:first-child {
        border-left: none;
        padding: 0 8px;
    }
}

.ya-social__title {
    color: #2e2e30;
    font-size: 12px;
    text-transform: uppercase;
}

.ya-social__a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    text-decoration: none;
    font-weight: 700;
    transition: all $global-transition-duration linear;

    &--Vk {
        color: #45688e;

        &:hover {
            background: #45688e;
            color: white;
        }
    }

    &--f {
        color: #3b5998;

        &:hover {
            background: #3b5998;
            color: white;
        }
    }

    &--t {
        color: #1da1f2;
        font-size: 16px;

        &:hover {
            background: #1da1f2;
            color: white;
        }
    }

    &--O {
        color: #f2720c;

        &:hover {
            background: #f2720c;
            color: white;
        }
    }
}

.ya-icon {

    .ya-social__a & {
        margin: 0;
    }
}
