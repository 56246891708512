.ya-login {
    margin-bottom: 50px;
}

.ya-login__row {
    @include cols(2, 20px);
    flex-wrap: wrap;

    &.ya-align-center {
        align-items: center;
    }

    &.ya-no-cols {
        @include cols(default, 20px);
    }
}

.ya-login__block {
    border-radius: 5px;
    border: 1px solid #dfdfdf;
    background-color: #fcfcfc;
    padding: 56px 0;
}

.ya-login__container {
    padding: 0 40px;

    &.ya-border {
        border-top: 1px solid #dfdfdf;
    }

    & + & {
        padding-top: 28px;
    }
}

.ya-login__title {
    display: block;
    color: #1b1b1b;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 14px;
}

[type=submit] {

    .ya-login & {
        margin: 8px 0 25px;
    }
}

.ya-field-container {

    .ya-login & {
        margin-bottom: 20px;
    }
}

