.ya-field-container {

    .ya-search__form & {
        position: relative;
    }
}

[type=submit] {

    .ya-search__form & {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        padding: 0 13px;
    }
}

.ya-field {

    .ya-search__form & {
        padding-left: 46px;
    }
}
