.ya-feedback {

    .ya-footer & {
        margin-bottom: 8px;
    }
}

.ya-feedback__form-container {

    & > *:not(:last-child) {
        margin-bottom: 15px;
    }
}

.ya-feedback__form-row {
    @include cols(auto, 20px);
}

.ya-feedback__title {
    display: block;
    color: white;
    font-family: $font-helvetica;
    font-size: 13px;
    text-transform: uppercase;
    text-decoration: underline;
    transition: all $global-transition-duration linear;

    &:hover {
        color: $anchor-color-hover;
    }
}

