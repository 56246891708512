.ya-filter__header {
    padding: $sidebar-container-padding-y $sidebar-container-padding-x;
    @include cols(default shrink, 20px in, 'span');
    align-items: center;
    justify-content: space-between;
    color: #1b1b1b;
    font-size: 16px;
    font-weight: 700;
}

.ya-icon {

    .ya-filter__header & {
        margin: 0;
        transition: transform 0.4s;

        .open & {
            transform: rotateX(180deg);
        }
    }
}

.ya-filter__li {

    & + & {
        border-top: 1px solid $sidebar-border-color;
    }
}

.ya-filter__buttons {
    display: flex;
    flex-direction: column;
}

.ya-button {

    .ya-filter__buttons & + & {
        margin-top: 11px;
    }
}

.ya-field-container.ya-typeahead {

    & + & {
        margin-top: 9px;
    }
}
