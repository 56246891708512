// Настройки проекта

// 1. Глобальные
// 2. Типографика
// 3. Кнопки
// 4. Формы
// 5. Breakpoints
// 6. Фиксированный header
// 7. Modular scale
// 8. Переменные проекта


// 1. Глобальные //
// ----------------

$class-container-page: 'ya-page';
$class-header: 'ya-header';
$class-container-content: 'ya-content';
$class-footer: 'ya-footer';
$class-container: 'ya-container';
$class-title: 'ya-title';
$class-container-text: 'ya-text';
$class-link: 'ya-link';
$class-icon: 'ya-icon';
$class-hidden: 'ya-hidden';

$global-path: "__static__"; // путь к изображениям
$global-padding: 10px; // отступы по краям страницы
$global-width: 1075px; // ширина основного контейнера. 0 для ширины 100%
$global-font-size: 14px;
$global-lineheight: 1.3;
$global-font-color: #3c3d41;
$global-weight-normal: 400;
$global-weight-bold: 700;
$global-background: white;
$global-box-sizing: border-box;
$global-transition-duration: .1s;
$global-transition-duration-icons: $global-transition-duration;
$body-antialiased: true;

$selection-background-color: #2e2e30;
$selection-color: #fff;
$selection-text-shadow: none;

$z-index-base: 1;
$z-index-hover: 10;
$z-index-fixed: 20;
$z-index-popup: 30;
$z-index-hidden: -1;

// 2. Типографика //
// -----------------

$anchor-color: #e7221b;
$anchor-color-hover: #d30e07;
$anchor-transition-duration: $global-transition-duration;
$anchor-border: none;
$anchor-border-color-hover: transparent;

$header-font-family: $font-main;
$header-font-size: 32px;
$header-font-weight: 700;
$header-font-style: normal;
$header-color: #1b1b1b;
$header-lineheight: $global-lineheight;
$header-margin-bottom: 0px;

$paragraph-lineheight: $global-lineheight;
$paragraph-margin-bottom: 18px;

$list-lineheight: $paragraph-lineheight;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-type: disc;
$list-style-position: inside;
$list-side-margin: 0px; // отступ слева
$list-nested-side-margin: 10px; // отступ вложенного списка

// 3. Кнопки //
// ------------

$button-class: 'ya-button';

$button-border-width: 1px; // для выключения - 0
$button-border-style: solid;
$button-height: 40px;
$button-width: auto;
$button-padding: 15px; // отступы слева и справа
$button-font-family: $font-main;
$button-font-weight: 700;
$button-font-size: 12px;
$button-transition-duration: $global-transition-duration;
$button-active-transition-duration: 0s;
$button-radius: 5px;
$button-text-transform: uppercase;

// 4. Формы //
// -----------

$field-class: 'ya-field';
$field-group-class: 'ya-field-group';
$field-container-class: 'ya-field-container';
$field-class-error: 'ya-error';

$field-color: $global-font-color;
$field-background: #eee;
$field-border-width: 1px;
$field-border-style: solid;
$field-border-color: #d7d7d7;
$field-height: 44px;
$field-font-family: $font-main;
$field-font-size: 15px;
$field-font-weight: 400;
$field-padding: 15px;
$field-border-radius: 5px;
$field-transition-duration: $global-transition-duration;

$field-focus-color: $field-color;
$field-focus-background: $field-background;
$field-focus-border-color: $field-border-color;

$field-textarea-height: 140px;
$field-textarea-padding: 8px;

$field-placeholder-color: gray;
$field-placeholder-hide-focus: false; // boolean. Скрытие placeholder при фокусе
$field-placeholder-transition-duration: 0.3s; // скорость скрытия

$field-error-color: red;
$field-error-border-color: $field-error-color;
$field-error-background: $field-background;

// 5. Breakpoints //
// -----------------

$breakpoints: (
    xsmall: 0,
    small: 480px,
    medium: 768px, // 510px + $global-padding-medium-down * 2
    large: 992px, // $global-max-width + $global-padding-medium-down * 2
    xlarge: 1200px,
    xxlarge: 1440px,
);

// 6. Фиксированный header //
// --------------------------

$fixed-header: true; // фиксированный header
$class-header-container: '#{$class-header}__fixed-container'; // контейнер для фиксированного блока
$class-fixed-header: '#{$class-header}__fixed'; // фиксированный блок
$height-fixed-header: auto;

// 7. Modular scale //
// -------------------

$ms-start-breakpoint: 992px; // начало масштабирования (вниз)
$ms-end-breakpoint: 400px; // конец масштабирования (вниз)
$ms-num-steps-breakpoints: 7; // количество шагов

// 8. Переменные проекта
// ---------------------

$sidebar-container-padding-y: 15px;
$sidebar-container-padding-x: 18px;
$sidebar-border-color: #eee;

$orange: #ffdc17;
