$background-dark: #262729;

.ya-footer {
    color: #f8f8f8;
}

.ya-footer__block {

    &--top {
        background-color: #49494a;
        padding: 41px 0;
    }

    &--middle {
        background: $background-dark url("__static__/general/bg-footer.png") repeat;
        padding: 30px 0;
    }

    &--bottom {
        background-color: $background-dark;
        background: $background-dark url("__static__/general/bg-footer.png") repeat;
        border-top: 1px solid #49494a;
        padding: 16px 0;
    }
}

.ya-footer__row {
    display: flex;
    justify-content: space-between;

    &--menu {
        @include gutter(60px);
    }
}

.ya-col {

    .ya-footer__row--menu & {

        &:last-child {
            border-left: 1px solid #4d4f53;
        }
    }
}

.ya-footer__title {
    display: block;
    font-weight: 700;
    font-family: $font-helvetica;
    margin-bottom: 10px;
}

p {

    .ya-footer__text & {
        color: #737373;
        font-size: 12px;
    }
}

.ya-footer__link {
    color: inherit;
    text-decoration: none;
    transition: all $global-transition-duration linear;

    &:hover {
        color: $anchor-color-hover;
    }
}
