@import "yaTabs-core";

.ya-tabs__buttons {
    //display: flex;
    //justify-content: center;
    margin-bottom: 28px;
}

.ya-tabs__buttons-ul {
    display: flex;
    //justify-content: center;
    text-align: center;
    border-radius: 4px;
    overflow: hidden;
    background-color: #eee;
    border: 1px solid #d7d7d7;
}

.ya-tabs__buttons-li {
    flex: 1 1 0%;
}

.ya-tabs__button {
    background: #eee;
    display: block;
    line-height: 42px;
    cursor: pointer;
    width: 100%;
    height: 42px;
    font-weight: 700;
    color: #959597;
    transition: all $global-transition-duration linear;
    text-decoration: none;

    .ya-tabs__buttons-li:not(:first-child) & {
        border-left: 1px solid #d7d7d7;
    }

    &:hover {
        background: white;
        color: #1b1b1b;

        .ya-theme-orange & {
            background: $orange;
        }
    }

    &.ya-tabs-active {
        background: white;
        color: #1b1b1b;

        .ya-theme-orange & {
            background: $orange;
        }
    }

    .ya-tabs__buttons.ya-lg & {
        font-family: $font-helvetica;
        font-size: 15px;
        font-weight: 400;
    }
}

.ya-tabs__list-li {
    transition: all 0s linear;
}


