.ya-account-data {
    margin-bottom: 60px;
}

.ya-account-form__row {
    @include cols(2, 20px);
    flex-wrap: wrap;
}

.ya-field-container {

    .ya-account-form__row & {
        margin-bottom: 10px;
    }
}

.ya-account-form__container {
    margin-bottom: 27px;
}
