.ya-bonus {
    margin-bottom: 20px;

    .ya-sidebar__block & {
        @extend .ya-sidebar__container;
        margin-bottom: 0;
    }

    &.ya-right {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
    }
}

.ya-bonus__row {

    &.ya-main {
        @include cols(default shrink, 10px in);
        align-items: center;
        justify-content: space-between;

        .ya-sidebar__block & {
            padding: 13px 0 11px;
        }
    }

    &.ya-in {
        @include cols(default, 6px in);
        align-items: center;
    }
}

.ya-bonus__text {
    color: #e7221b;
    font-size: 12px;
    text-transform: uppercase;
    //line-height: 1;
    display: inline-block;

    &.ya-lg {
        font-size: 30px;
        font-weight: 700;
    }
}

[data-remodal-target] {

    .ya-bonus & {
        position: relative;
        top: -4px;
    }
}

.ya-bonus__title {
    display: block;
    color: #899394;
    font-family: $font-helvetica;
}
