.ya-tabs__list-ul {
    @include cols(1, 0, 'li');

    max-width: 100%;
    overflow: hidden;
    position: relative;
    z-index: $z-index-base - 1;
}

.ya-tabs__list-li {
    flex-shrink: 0;
    display: none;

    &:first-child {
        display: block;
    }

    .ya-tabs-ready & {
        display: none;
        opacity: 0;
        visibility: hidden;
        z-index: $z-index-base;
        top: 0;
        left: 0;
        width: 100%;
    }

    &.ya-tabs-active {
        z-index: $z-index-base + 1;
        opacity: 1;
        visibility: visible;
        display: block;
    }
}
