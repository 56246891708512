.ya-orders__list {
    margin-bottom: 34px;
}

.ya-orders__list-buttons {
    display: flex;
    width: 202px;
    flex-wrap: wrap;
    border: 1px solid #aaa;
    border-radius: 3px;
    overflow: hidden;
}

.ya-col {

    .ya-orders__list-buttons & {
        flex: 1 1 auto;
        min-width: 50%;
        border-top: 1px solid #aaa;
        border-left: 1px solid #aaa;
        margin-left: -1px;

        &:first-child {
            border-top: none;
        }
    }
}

.ya-button {

    .ya-orders__list-buttons & {
        padding: 0;
        width: 100%;
        border: none;
        border-radius: 0;
        line-height: 32px - $button-border-width * 2;
    }
}

.ya-orders__list-row {
    @include cols(default shrink, 40px in);
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
}

.ya-orders__list-li {
    background-color: #fcfcfc;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
}

.ya-orders__list-container {
    padding: 0 17px;
}

.ya-orders__list-header {
    background-color: gainsboro;
    padding: 10px 0;
}

.ya-orders__list-header-row {
    @include cols(default shrink, 40px in, 'span');
    justify-content: space-between;
    align-items: center;
}

.ya-orders__list-options-name {
    color: #6c6c6c;
}

.ya-orders__list-options-value {
    font-size: 16px;
    font-weight: 700;
}

.ya-orders__list-options-li {
    margin-bottom: 5px;

    &:last-child {
        margin-bottom: 0;
    }
}

.ya-orders__info-ul {
    margin-bottom: 63px;
}

.ya-orders__info-li {
    margin-bottom: 27px;
}

.ya-orders__info-title {
    display: block;
    font-family: $font-helvetica;
    font-weight: 700;
    color: #b8bebf;
    margin-bottom: 9px;
    padding: 0 10px;
}

.ya-orders__info-text {
    display: block;
    background-color: #efefef;
    font-size: 16px;
    padding: 12px 10px 11px;
    margin-bottom: 10px;
}

.ya-orders__info-old-price {
    display: block;
}

.ya-orders__info-num {
    font-size: 18px;
    font-weight: 700;
    color: #191919;
}
